import request from '@/utils/request'
const url = '/index/Aftersales/'
// ----------------内容管理----------------
/**
 * 内容提交
 * @param {array} params 请求参数
 */
export function add(data) {
  return request({
    url: url + 'add',
    method: 'post',
    data
  })
}
//分配人员
export function allot(data) {
  return request({
    url: url + 'allot',
    method: 'post',
    data
  })
}
//完成任务
export function finish(data) {
  return request({
    url: url + 'finish',
    method: 'post',
    data
  })
}
/**
 * 产品分类列表
 * @param {array} params 请求参数
 */
export function category(params) {
  return request({
    url: url + 'category',
    method: 'get',
    params: params
  })
}
/**
 * 文件添加
 */
export function fileadd() {
  return process.env.VUE_APP_BASE_API + '/admin/file.File/add'
}
/**
 * 搜索列表
 * @param {array} params 请求参数
 */
export function search_list(params) {
  return request({
    url: url + 'search_list',
    method: 'get',
    params: params
  })
}
/**
 * 搜索内容
 * @param {array} params 请求参数
 */
export function info(params) {
  return request({
    url: url + 'info',
    method: 'get',
    params: params
  })
}
/**
 * 列表
 * @param {array} params 请求参数
 */
export function list(params) {
  return request({
    url: url + 'list',
    method: 'get',
    params: params
  })
}
//获取企业微信的应用可见范围人员
export function get_qiyewechet_allmember(params) {
  return request({
    url: url + 'get_qiyewechet_allmember',
    method: 'get',
    params: params
  })
}
//获取访问用户身份
export function get_user(params) {
  return request({
    url: url + 'get_user',
    method: 'get',
    params: params
  })
}